<template>
  <div class="d-flex flex-column gap-4">
    <el-dropdown trigger="click" class="align-self-end" @command="handleMissingBillings($event)">
      <Button
        class="batch-actions"
        :disabled="!selectedData.length"
        :style="{ padding: '4px' }"
        :class="$direction === 'rtl' ? 'pe-4' : 'ps-4'"
      >
        {{
          $tc('tasks.balanceAlignmentTask.missingBillings.actions.title', selectedData.length, {
            count: selectedData.length,
          })
        }}

        <ChevronIcon direction="down" />
      </Button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :command="ACTIONS.REQUEST_MISSING_EVENTS">
          <div class="d-flex align-items-center gap-2 text-typography-primary">
            <RequestIcon />
            <p>
              {{ $t('tasks.balanceAlignmentTask.missingBillings.actions.requestMissingEvents') }}
            </p>
          </div>
        </el-dropdown-item>
        <el-dropdown-item :command="ACTIONS.CLOSE_MISSING_EVENTS">
          <div class="d-flex align-items-center gap-2 text-typography-primary">
            <CheckCircleIcon />
            <p>
              {{ $t('tasks.balanceAlignmentTask.missingBillings.actions.closeMissingEvents') }}
            </p>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <Table
      show-index
      :data="tableData"
      :columns="columns"
      :row-selection.sync="rowSelection"
      rounded
      border
      :hover="false"
      class="missing-billings-table"
      :cell-class="handleCellClass"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 }}
      </template>
      <template #cell-date="{ rowData: { date } }">
        {{ formatDate(date) }}
      </template>
      <template #cell-details="{ rowData: { details, parentDocumentId } }">
        <Button type="link" class="p-0 text-typography-primary" @click="$emit('open-document', parentDocumentId)">
          {{ details }}
        </Button>
      </template>
      <template #cell-actions="{ rowData: { id }, rowIndex }">
        <el-dropdown
          class="d-flex justify-content-center"
          trigger="click"
          @command="(method) => _self[method]({ id, data: { resolved: true } })"
          @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
        >
          <Button type="icon" class="action-button p-0" :class="{ active: activeActions === rowIndex }" @click.stop>
            <KebabIcon />
          </Button>
          <el-dropdown-menu>
            <el-dropdown-item command="patchMissingEvent">
              {{ $t('tasks.balanceAlignmentTask.missingBillings.resolveMissingEvent') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <ConfirmationModal
      v-if="showConfirmation"
      :title="$t('tasks.balanceAlignmentTask.missingBillings.confirmModal.title')"
      :content="
        $t('tasks.balanceAlignmentTask.missingBillings.confirmModal.body', {
          count: selectedData.length,
        })
      "
      :confirm="$t('tasks.balanceAlignmentTask.missingBillings.confirmModal.delete')"
      :cancel="$t('tasks.balanceAlignmentTask.missingBillings.confirmModal.cancel')"
      @confirm="resolveMissingEvents"
      @cancel="showConfirmation = false"
    ></ConfirmationModal>
  </div>
</template>

<script>
const TABLE_HEADERS = {
  DATE: 'date',
  DOCUMENT_TYPE: 'documentType',
  DOCUMENT_NUMBER: 'documentNumber',
  TOTAL_AMOUNT: 'totalAmount',
  DETAILS: 'details',
  ACTIONS: 'actions',
};

const ACTIONS = {
  REQUEST_MISSING_EVENTS: 'requestMissingEvents',
  CLOSE_MISSING_EVENTS: 'closeMissingEvents',
};

import { ref, computed, getCurrentInstance } from 'vue';

import { Button, Table } from '@/modules/core';
import { CheckCircleIcon, ChevronIcon, KebabIcon, RequestIcon } from '@/assets/icons';
import { useDocumentsByIds } from '@/modules/document/compositions';
import { useMissingEvents, usePatchMissingEvent, usePatchMultipleMissingEvents } from '@/modules/reconciliation';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import ConfirmationModal from './ConfirmationModal';

export default {
  components: {
    Button,
    Table,
    KebabIcon,
    ChevronIcon,
    RequestIcon,
    CheckCircleIcon,
    ConfirmationModal,
  },
  props: {
    businessId: { type: String, required: true },
    supplierId: { type: String, required: true },
    missingBillings: { type: Array, required: true },
  },
  setup(props) {
    const { $message, $i18n } = getCurrentInstance().proxy;
    const { formatCentsToCurrency } = useCurrency();
    const { refetch: refetchMissingEvents } = useMissingEvents(
      () => ({ businessId: props.businessId, supplierId: props.supplierId }),
      () => ({ enabled: !!props.businessId && !!props.supplierId })
    );
    const {
      patchMissingEvent,
      onDone: patchMissingEventOnDone,
      onError: patchMissingEventOnError,
    } = usePatchMissingEvent();
    const {
      patchMultipleMissingEvents,
      onDone: patchMultipleMissingEventsOnDone,
      onError: patchMultipleMissingEventsOnError,
    } = usePatchMultipleMissingEvents();

    const success = () => {
      $message.success($i18n.t('billing.billingManagement.messages.missingEventResolvedSuccess'));
      refetchMissingEvents();
    };
    const error = () => $message.error($i18n.t('billing.billingManagement.messages.missingEventResolvedError'));

    patchMissingEventOnDone(success);
    patchMultipleMissingEventsOnDone(success);
    patchMissingEventOnError(error);
    patchMultipleMissingEventsOnError(error);

    const { documents: rehandleParentDocuments } = useDocumentsByIds(
      computed(() => ({
        businessId: props.businessId,
        ids: props.missingBillings.map(({ parentDocumentId }) => parentDocumentId),
      }))
    );

    const missingBillingsWithDoc = computed(() =>
      props.missingBillings.map((missingEvent) => ({
        ...missingEvent,
        parentDocument: rehandleParentDocuments.value?.find(
          (document) => document.id === missingEvent.parentDocumentId
        ),
      }))
    );

    return {
      missingBillingsWithDoc,
      patchMissingEvent,
      patchMultipleMissingEvents,
      activeActions: ref(null),
      formatCentsToCurrency,
    };
  },
  data() {
    return { ACTIONS, rowSelection: [], showConfirmation: false };
  },
  computed: {
    selectedData() {
      return Object.values(this.rowSelection).map((rowIndex) => this.tableData[rowIndex]);
    },
    columns() {
      return [
        {
          header: this.$t('modals.issues.missingDocuments.table.header.date'),
          key: TABLE_HEADERS.DATE,
          width: '105px',
        },
        {
          header: this.$t('modals.issues.missingDocuments.table.header.documentType'),
          key: TABLE_HEADERS.DOCUMENT_TYPE,
        },
        {
          header: this.$t('modals.issues.missingDocuments.table.header.documentNumber'),
          key: TABLE_HEADERS.DOCUMENT_NUMBER,
        },
        {
          header: this.$t('modals.issues.missingDocuments.table.header.totalAmount'),
          key: TABLE_HEADERS.TOTAL_AMOUNT,
        },
        {
          header: this.$t('modals.issues.missingDocuments.table.header.details'),
          key: TABLE_HEADERS.DETAILS,
        },
        { key: TABLE_HEADERS.ACTIONS, width: '35px' },
      ];
    },
    tableData() {
      return this.missingBillingsWithDoc
        .map(({ id, context, possibleTypes, reference, parentDocument, parentDocumentId }) => {
          return {
            id,
            [TABLE_HEADERS.DATE]: context.issueDate,
            [TABLE_HEADERS.DOCUMENT_TYPE]: this.getMissingType(possibleTypes),
            [TABLE_HEADERS.DOCUMENT_NUMBER]: reference,
            [TABLE_HEADERS.TOTAL_AMOUNT]: this.formatMoney(context.calculatedAmount),
            [TABLE_HEADERS.DETAILS]: this.getParentDocumentText(parentDocument),
            parentDocumentId,
          };
        })
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    },
  },
  methods: {
    async resolveMissingEvents() {
      await this.patchMultipleMissingEvents({
        batchParams: this.selectedData.map((missingEvent) => ({
          id: missingEvent.id,
          data: { resolved: true },
        })),
      });
      this.showConfirmation = false;
      this.rowSelection = [];
    },
    async handleMissingBillings(command) {
      switch (command) {
        case ACTIONS.REQUEST_MISSING_EVENTS: {
          this.$emit('request-missing-events', this.selectedData);
          break;
        }
        case ACTIONS.CLOSE_MISSING_EVENTS: {
          this.showConfirmation = true;
          break;
        }

        default:
          break;
      }
    },
    getMissingType(type) {
      return type.map((type) => this.$t(`document.exports.schema.type.shortName.${type}`)).join(' / ');
    },
    getParentDocumentText(parentDocument) {
      if (!parentDocument) return null;
      const documentTypeText = this.$t(`document.exports.schema.type.shortName.${parentDocument.type}`);
      return `${documentTypeText} ${this.formatDateShort2digitYear(parentDocument.issueDate)}`;
    },
    formatDate(date) {
      return date
        ? new Date(date).toLocaleDateString(this.$i18n.locale, { day: 'numeric', month: 'numeric', year: '2-digit' })
        : '-';
    },
    formatDateShort2digitYear(date) {
      return date
        ? new Date(date).toLocaleDateString(this.$i18n.locale, {
            month: 'short',
            year: '2-digit',
          })
        : '';
    },
    formatMoney(value) {
      return this.formatCentsToCurrency(value) ?? '-';
    },
    actionsVisibleChange(index, isVisible) {
      this.activeActions = isVisible ? index : null;
    },
    handleCellClass(rowIndex) {
      if (this.activeActions === rowIndex) return 'bg-secondary';
    },
  },
};
</script>

<style lang="scss">
.missing-billings-table {
  .action-button {
    &.active {
      visibility: visible;
    }
  }

  tr {
    .action-button {
      visibility: hidden;
    }

    &:hover .action-button {
      visibility: visible;
    }
  }
}

.batch-actions.is-disabled {
  background-color: #f3f3f4;
  color: #94989f;
  &:hover {
    background-color: #f3f3f4;
    color: #94989f;
  }
}
</style>
