<template>
  <PageLayout>
    <template #header>
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <h1><slot name="title"></slot></h1>
          <TaskTag class="mx-3" :task="task" :display-mode="true" />
        </div>
        <slot name="actions"></slot>
      </div>
    </template>
    <slot name="details"></slot>
    <slot name="content"></slot>
    <div class="w-100 d-flex justify-content-end">
      <slot name="action-btn"></slot>
    </div>
    <TaskSnoozeModal
      v-if="openSnoozeModal"
      :task="task"
      @update-task="$emit('handle-update-and-move-to-next-task', $event)"
      @close="$emit('close-snooze-modal')"
    >
      <template #subtile>
        <slot name="task-snooze-modal-subtitle" />
      </template>
    </TaskSnoozeModal>
  </PageLayout>
</template>

<script>
import { PageLayout } from '@/modules/core';
import { TaskTag } from '@/modules/tasks/taskTag';

import { TaskSnoozeModal } from '../../components';

export default {
  components: { PageLayout, TaskTag, TaskSnoozeModal },
  props: {
    task: { type: Object, required: true },
    openSnoozeModal: { type: Boolean, default: false },
  },
};
</script>
